/* You can add global styles to this file, and also import other style files */

.center-bottom {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 1rem 0 1rem 0;
  text-align: center;
}
